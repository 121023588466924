import {
  RH_MEMBERS_PROGRAM,
  IS_LIMITED_TO,
  INSUFFICIENT_INVENTORY,
  INSUFFICIENT_INVENTORY_FOR_RH_PROGRAM,
  NOT_COMPATIBLE_ERROR,
  NOT_COMPATIBLE_RESPONSE,
  NETWORK_ERROR
} from "resources/membership-resource.json";

export const membershipHandler = (error: string) => {
  if (error.toLowerCase().includes("is limited to")) {
    return `${RH_MEMBERS_PROGRAM} ${IS_LIMITED_TO} 1 per order.`;
  } else if (error.toLowerCase().includes(INSUFFICIENT_INVENTORY)) {
    return INSUFFICIENT_INVENTORY_FOR_RH_PROGRAM;
  } else if (error.toLowerCase().includes(NOT_COMPATIBLE_ERROR)) {
    return NOT_COMPATIBLE_RESPONSE;
  } else {
    return NETWORK_ERROR;
  }
};
