import template from "lodash/template";

export const getMemberSavingsText = (
  pageContent?: any,
  percentage: number = 0,
  minPercent?: number,
  maxPercent?: number
) => {
  const saveUpToSelectSale =
    pageContent?.SAVE_UP_TO_SELECT_SALE_ITEMS ||
    "SAVE UP TO <%= minPercent %>% ON SELECT SALE ITEMS";
  const saveRangeSelectSale =
    pageContent?.SAVE_ON_SELECT_SALE_ITEMS ||
    "SAVE <%= minPercent %>% - <%= maxPercent %>% ON SELECT SALE ITEMS";
  const saveUpToSelect =
    pageContent?.SAVE_UP_TO_SELECT_ITEMS ||
    "SAVE UP TO <%= minPercent %>% ON SELECT ITEMS";
  const saveRangeSelect =
    pageContent?.SAVE_ON_SELECT_ITEMS ||
    "SAVE <%= minPercent %>% - <%= maxPercent %>% ON SELECT ITEMS";
  const saveUpToAll =
    pageContent?.SAVE_UP_TO_ALL_ITEMS ||
    "SAVE UP TO <%= minPercent %>% ON ALL ITEMS";
  const saveRangeAll =
    pageContent?.SAVE_ON_ALL_ITEMS ||
    "SAVE <%= minPercent %>% - <%= maxPercent %>% ON ALL ITEMS";

  if (!minPercent && !maxPercent) return "";

  if (
    minPercent === maxPercent ||
    (minPercent && !maxPercent) ||
    (!minPercent && maxPercent)
  ) {
    if (percentage < 20) {
      return template(saveUpToSelectSale)({
        minPercent: minPercent || maxPercent
      });
    }
    if (percentage >= 20 && percentage < 100) {
      return template(saveUpToSelect)({ minPercent: minPercent || maxPercent });
    }
    return template(saveUpToAll)({ minPercent: minPercent || maxPercent });
  }

  if (percentage < 20) {
    return template(saveRangeSelectSale)({ minPercent, maxPercent });
  }
  if (percentage < 100) {
    return template(saveRangeSelect)({ minPercent, maxPercent });
  }
  return template(saveRangeAll)({ minPercent, maxPercent });
};
