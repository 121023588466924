import { getBrand } from "hooks-use-brand/useBrand";
import { mapArrayRecursivelyWith } from "./mapArrayRecursivelyWith";

export const prefixTargetUrlsWithLocalization = (
  childCategories: NavigationElement[],
  prefix = ""
) => {
  return mapArrayRecursivelyWith({
    array: childCategories,
    nestedKey: "childCategories",
    cb: value => {
      const targetUrl: string = value?.targetUrl;

      if (targetUrl) {
        try {
          const { hostname, origin, pathname, search } = new URL(targetUrl);

          const newHostname = hostname
            ?.split(".")
            ?.filter(value => !value?.startsWith("stg"))
            ?.join(".");

          const brand = getBrand(newHostname, {
            get: (target, name: string) => target[name] || target[newHostname]
          });

          if (brand) {
            return Object?.assign(value, {
              targetUrl: `${origin}${prefix}${pathname}${search}`
            });
          }

          return value;
        } catch (error) {}
      }

      return value;
    }
  });
};
