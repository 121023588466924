import { Country } from "utils/formHelpers";
import { countries } from "@RHCommerceDev/resources/countries-config.json";

/**@deprecated - use getTranslatedCountriesList*/
export const getCountriesList = (
  international: boolean,
  FEATURE_INTERNATIONAL?: {
    FEATURE_EU_EXPANSION_BE?: boolean;
    FEATURE_EU_EXPANSION_DE?: boolean;
    FEATURE_EU_EXPANSION_ES?: boolean;
    FEATURE_EU_EXPANSION_FR?: boolean;
  }
): Country[] => [
  { code: "US", name: "United States" },
  { code: "CA", name: "Canada" },
  ...(international ? [{ code: "GB", name: "United Kingdom" }] : []),
  ...(FEATURE_INTERNATIONAL?.FEATURE_EU_EXPANSION_BE
    ? [{ code: countries.BE.value, name: countries.BE.name }]
    : []),
  ...(FEATURE_INTERNATIONAL?.FEATURE_EU_EXPANSION_DE
    ? [{ code: countries.DE.value, name: countries.DE.name }]
    : []),
  ...(FEATURE_INTERNATIONAL?.FEATURE_EU_EXPANSION_ES
    ? [{ code: countries.ES.value, name: countries.ES.name }]
    : []),
  ...(FEATURE_INTERNATIONAL?.FEATURE_EU_EXPANSION_FR
    ? [{ code: countries.FR.value, name: countries.FR.name }]
    : [])
];

export type TranslatedCountries = Record<keyof typeof countries, string>;

export const getTranslatedCountriesList = (
  translatedCountries: TranslatedCountries,
  exclude?: Partial<Record<keyof typeof countries, boolean>>
): Pick<Country, "code" | "name">[] => {
  const normalizedCountries = Object.entries(translatedCountries || {}).map(
    ([code, name]) => ({ code, name })
  );

  return (
    normalizedCountries?.filter(country => {
      if (exclude && exclude[country.code]) return false;
      return true;
    }) || []
  );
};
