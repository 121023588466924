import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { sanitizeQueryString } from "./SanitizationUtility";

function isEqual(setA, setB) {
  if (setA.size !== setB.size) return false;
  for (const item of setA) {
    if (!setB.has(item)) return false;
  }
  return true;
}

const SanitizedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const location = useLocation();

  const sanitizedSearch = sanitizeQueryString(location.search);

  useEffect(() => {
    const originalKeys = new Set(Object.keys(location.search));
    const sanitizedKeys = new Set(
      Object.keys(sanitizeQueryString(location.search))
    );

    const isXSSDetected = !isEqual(originalKeys, sanitizedKeys);
    if (isXSSDetected) {
      const searchToReplace =
        location?.search?.includes("N=") ||
        location?.search?.includes("filter=")
          ? location.search
          : sanitizedSearch;

      // const encodedSearch = searchToReplace.replace(
      //   /\+/g,
      //   encodeURIComponent("+")
      // );
      history.replace({ search: searchToReplace });
    }
  }, [sanitizedSearch, location.search, history]);
  return <Route {...rest} location={location} component={Component} />;
};

export default SanitizedRoute;
