import useIsoRedirect from "hooks-use-isoredirect";

function isAbsolute(url: string) {
  return /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url);
}

export default (targetUrl: string, history: any) => {
  // check for absolute path
  if (isAbsolute(targetUrl)) {
    useIsoRedirect(targetUrl, "windowAssign");
  } else {
    history.push(targetUrl);
  }
};
