import React from "react";
import { DOMParser as Parser } from "xmldom";
import { processEnvServer } from "hooks/useSsrHooks";
const parser = processEnvServer ? Parser : DOMParser;
const getNodes = str =>
  new parser().parseFromString(str, "text/html")?.body?.childNodes || [];
const createJSX = nodeArray => {
  return nodeArray.map(node => {
    let attributeObj = {};
    const { attributes, localName, childNodes, nodeValue } = node;
    if (attributes) {
      Array.from(attributes).forEach(attribute => {
        if ((attribute as any).name === "style") {
          let styleAttributes = (attribute as any).nodeValue.split(";");
          let styleObj = {};
          styleAttributes.forEach(attribute => {
            let [key, value] = attribute.split(":");
            styleObj[key] = value;
          });
          attributeObj[(attribute as any).name] = styleObj;
        } else {
          attributeObj[(attribute as any).name] = (attribute as any).nodeValue;
        }
      });
    }
    return localName
      ? React.createElement(
          localName,
          attributeObj,
          childNodes && Array.isArray(Array.from(childNodes))
            ? createJSX(Array.from(childNodes))
            : []
        )
      : nodeValue;
  });
};

export const StringToJSX = props => {
  return createJSX(Array.from(getNodes(props.domString)));
};
