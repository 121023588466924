import { REQUIRED_PERMISSION_COUNTRIES } from "./constants";
import { useEnv } from "hooks/useEnv";
import { CookieSetOptions } from "universal-cookie";
import {
  StorageType,
  storagesMap,
  StorageItemType,
  excludedCookies,
  durationMap,
  CookiesKeys,
  LocalSessionStorageKeys
} from "./cookies-permission";
import yn from "yn";

const env = useEnv();

export type CheckPermissionProps<T extends StorageType> = {
  cookiePreferences: SessionUserType["cookiePreferences"];
  storageType: T;
  storageKey: string;
};

export type GetStorageOptionsProps = {
  permissionCountry?: string;
  domain?: string;
  expiredDate?: Date;
  cookiePreferences: SessionUserType["cookiePreferences"];
};

function checkPermission<T extends StorageType>({
  storageType,
  storageKey,
  cookiePreferences
}: CheckPermissionProps<T>) {
  const storageItem = storagesMap[storageType][storageKey] as StorageItemType;
  const isPermittedByPreference =
    storageItem.preference === "strictlyNecessary" ||
    !!yn(cookiePreferences?.[storageItem.preference]) ||
    excludedCookies.includes(storageKey as string) ||
    !!yn(cookiePreferences?.userAcceptedAllCookies);

  return {
    duration: durationMap[storageItem.duration],
    isPermittedByPreference
  };
}

type GetCookieOptions = GetStorageOptionsProps & {
  storageType: "cookiesStorage";
  storageKey: CookiesKeys;
};

type GetStorageOptions = GetStorageOptionsProps & {
  storageType: "localSessionStorage";
  storageKey: LocalSessionStorageKeys;
};

type GetStorageOptionsReturn = {
  allowSetStorageItem: boolean;
  cookieOptions: CookieSetOptions;
};

export function getStorageOptions(
  props: GetCookieOptions
): GetStorageOptionsReturn;
export function getStorageOptions(
  props: GetStorageOptions
): GetStorageOptionsReturn;

export function getStorageOptions({
  permissionCountry = "",
  storageType,
  storageKey,
  cookiePreferences,
  domain,
  expiredDate
}) {
  const options: CookieSetOptions = {
    path: "/",
    domain
  };
  const { duration, isPermittedByPreference } = checkPermission({
    storageType,
    storageKey,
    cookiePreferences
  });
  const isPermittedByCountry =
    !REQUIRED_PERMISSION_COUNTRIES?.includes(permissionCountry) ?? false;

  const allowSetStorageItem = isPermittedByCountry || isPermittedByPreference;

  if (allowSetStorageItem) {
    options.maxAge = duration;
  } else {
    options.maxAge = 0;
    options.expires = expiredDate;
  }

  return {
    cookieOptions: options,
    allowSetStorageItem
  };
}

export const removeCookieOptions = () => {
  const options: CookieSetOptions = {
    path: "/",
    domain: env?.ENV_DOMAIN
  };
  return options;
};

export default getStorageOptions;
