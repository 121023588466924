import queryString from "query-string";
import sanitizeHtml from "sanitize-html";

export const sanitizeParams = params => {
  const sanitizedParams = {};
  Object.keys(params).forEach(key => {
    if (Array.isArray(params[key])) {
      sanitizedParams[key] = params[key].map(val => sanitizeHtml(val ?? ""));
    } else {
      sanitizedParams[key] = sanitizeHtml(params[key] ?? "");
    }
  });
  return sanitizedParams;
};

export const sanitizeQueryString = search => {
  const parsedParams = queryString.parse(search);
  const sanitizedParams = sanitizeParams(parsedParams);
  const sanitizedQueryString = queryString.stringify(sanitizedParams);
  return sanitizedQueryString;
};
