const EMITTER_NAMESPACE = "emitter-";

type FireFunction = () => void;

let queue: FireFunction[] = [];
let queueProcessingPromise: Promise<void> | null = null;

const processQueue = (): Promise<void> => {
  queueProcessingPromise = new Promise<void>(resolve => {
    queue.forEach(fire => fire());
    queue = [];
    resolve();
  });

  return queueProcessingPromise;
};

export const COMPONENT_DID_LOAD_EVENT = "component-did-load";

interface CustomEventListener {
  (event: CustomEvent): void;
}

interface EventManager {
  dispatch: (eventKey: string, data?: unknown, immediate?: boolean) => void;
  subscribe: (eventKey: string, callback: CustomEventListener) => void;
  remove: (eventKey: string, callback: CustomEventListener) => void;
}

const eventManager: EventManager = {
  dispatch: (eventKey: string, data?: unknown, immediate = false) => {
    const fire: FireFunction = () =>
      document.body.dispatchEvent(
        new CustomEvent(`${EMITTER_NAMESPACE}${eventKey}`, {
          detail: data
        })
      );

    if (immediate) {
      fire();
    } else {
      queue.push(fire);

      if (!queueProcessingPromise) {
        processQueue().then(() => {
          queueProcessingPromise = null;
        });
      }
    }
  },
  subscribe: (eventKey: string, callback: CustomEventListener) => {
    const wrapper = (event: Event) => callback(event as CustomEvent);
    document.body.addEventListener(`${EMITTER_NAMESPACE}${eventKey}`, wrapper);
  },
  remove: (eventKey: string, callback: CustomEventListener) => {
    const wrapper = (event: Event) => callback(event as CustomEvent);
    document.body.removeEventListener(
      `${EMITTER_NAMESPACE}${eventKey}`,
      wrapper
    );
  }
};

export default eventManager;
