import { getBrand } from "@RHCommerceDev/hooks-use-brand/useBrand";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";

export const getSourceCode = ({
  isPaymentPortal,
  place
}: {
  isPaymentPortal?: boolean;
  place: string;
}) => {
  const env = useEnv();
  const brand = getBrand();
  const country = getCountryFromUrl();

  const instance = isPaymentPortal ? "PAYMENT_PORTAL" : "WEB";

  return `${env.APP_ID}_${brand}_${country}_${instance}_${place}`.toUpperCase();
};
