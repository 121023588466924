interface filterArrayRecursivelyWithArgs {
  array: Array<any>;
  nestedKey?: string;
  cb?: (arg) => boolean;
}

export const filterArrayRecursivelyWith = (
  arg: filterArrayRecursivelyWithArgs
) => {
  const { array = [], nestedKey = "", cb = value => value } = arg ?? {};

  return array?.filter?.(value => {
    const shouldKeep = cb(value);

    if (shouldKeep && value?.[nestedKey]) {
      value[nestedKey] = filterArrayRecursivelyWith({
        array: value?.[nestedKey],
        nestedKey,
        cb
      });
    }

    return shouldKeep;
  });
};
