import { useEnv } from "hooks/useEnv";

const env = useEnv();
const isConcierge = (env?.APP_ID || "").includes("CONCIERGE");

// Concierge allows all International phone number formates.
const PHONE_PATTERN = isConcierge
  ? /^(\+?[0-9]{1,3})?[\s.-]?([0-9]{1,4})?[\s.-]?[0-9]{1,4}[\s.-]?[0-9]{1,4}[\s.-]?[0-9]{1,4}[\s.-]?[0-9]{1,4}?$/
  : /^([+]?(1([ .-])?)?)?[0-9]{3}[ .-]?[0-9]{3}[ .-]?[0-9]{4}$/;

export const isValidPhone = (value: string): boolean => {
  if (value && PHONE_PATTERN.test(value)) {
    return true;
  }
  return false;
};

export const removePhoneFormatting = (value: string): string => {
  return value.replace(/\D/g, "");
};

/**
 * formats a phone number string. removes any existing formatting then formats to a normalized form.
 * Example: formatPhoneNumber('+12345678900') // => "+1 (234) 567-8900"
 * Example: formatPhoneNumber('2345678900')   // => "(234) 567-8900"
 * @param value the phone number to format
 */
export const formatPhoneNumber = (value: string): string | null => {
  const cleaned = ("" + value).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};
