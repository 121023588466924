import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { getReqContext } from "./reqContext";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
export const getClientOrigin = () => {
  const req = getReqContext();
  const isxHost = req?.get("x-request-host") || req?.get("X-Request-Host");
  const [xhost] = isxHost ? isxHost.split(",") : [];
  const env = getGlobal("_env");
  const FALLBACK = processEnvServer
    ? process?.env?.REACT_APP_BFF_ORIGIN
    : env.REACT_APP_BFF_ORIGIN;
  let host =
    xhost ||
    req?.get("Client-Origin") ||
    req?.get("Origin") ||
    req?.get("Host") ||
    FALLBACK;

  if (process.env.ENV_ID === "LOCAL") {
    host = host?.replace(/:\d{1,4}$/g, "");
  }

  return host?.replace(/^\w+:\/\//, "");
};
