const storage: any = [];
export const memoryStorage = {
  setItem: (key: string, value: any) => {
    storage[key] = value;
  },
  getItem: (key: string) => {
    return storage[key];
  },
  removeItem: (key: string) => {
    delete storage[key];
  }
};
