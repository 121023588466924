const customFetch = (uri: string, options: any) => {
  const url = new URL(uri);

  Object.assign(options.headers, {
    "Client-Origin": window.location.origin
  });

  url.searchParams.set(
    "query",
    (url.searchParams.get("query") || "").replace(/(?:\s)+/g, " ")
  );

  const fetchURL = `${url.origin}${url.pathname}${url.search}`;

  return fetch(fetchURL, options);
};

export default customFetch;
