import * as Gsap from "gsap";
import * as ScrollTrigger from "gsap/ScrollTrigger";
import { useComponentLoadedListener } from "@RHCommerceDev/hooks/useComponentLoadedListener";
import useMediaString from "hooks/useMediaString";
import { useLayoutEffect } from "react";
import * as PerformAnimations from "utils/animation/performAnimations";
import { RHAnimatableComponentProps } from "utils/animation/types";

export function useAnimation(props: RHAnimatableComponentProps) {
  const breakpoint = useMediaString();

  useLayoutEffect(() => {
    let cleanup: Function | false | undefined = undefined;

    Promise.all([
      import("gsap"),
      import("gsap/ScrollTrigger"),
      import("./performAnimations")
    ]).then(([a, b, c]) => {
      const gsap: typeof Gsap = a as any;
      const scrollTrigger: typeof ScrollTrigger = b as any;
      const performAnimations: typeof PerformAnimations.performAnimations =
        c.performAnimations as any;

      gsap.default.registerPlugin(scrollTrigger.default);

      if (cleanup === false) {
        return;
      }

      cleanup = performAnimations(breakpoint, props);
    });

    return () => {
      if (typeof cleanup === "function") {
        cleanup();
      }

      cleanup = false;
    };
  }, [breakpoint, props]);

  useComponentLoadedListener(() => {
    import("gsap/ScrollTrigger").then(scrollTrigger => {
      scrollTrigger.default.refresh();
    });
  });
}
