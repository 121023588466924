import { ErrorInfo } from "components/RHNotificationModal/RHNotificationContext";

export interface PossibleErrorData {
  trace_id?: string;
  timestamp?: string;
}

export interface CartError {
  message?: string;
  httpStatusCode?: string;
  httpStatusMsg?: string;
  errorCode?: string;
  cartId?: string;
}

export interface PcsError {
  errorCode: string;
  errorMessages: {
    CARD_INVALID: string;
  };
  fieldErrors: Record<string, unknown>;
  formErrors: Record<string, unknown>;
  messages: Record<string, unknown>;
  statusCode: string;
}

export interface Error3DS {
  action?: {
    authorisationToken?: string;
    paymentData?: string;
    paymentMethodType?: string;
    subtype?: string;
    token?: string;
    type?: string;
  };
  code?: string;
  detailedMessage?: string;
  errorCode?: string;
  httpStatusCode?: string;
  httpStatusMsg?: string;
  message?: string;
  reason?: string;
}
/**
 * # Json Parse Error
 * Convert this string:
 * 400 - {"message":"RH Gift Card has already been applied to the order","httpStatusCode":"400","httpStatusMsg":"Bad Request","errorCode":"CART_INVALID","cartId":"e630e51c-3e97-44aa-bedf-845954184640"}
 * into valid json:
 * "RH Gift Card has already been applied to the order"
 * { message?: string, httpStatusCode?: string, httpStatusMsg?: string, errorCode?: string, cartId?: string }
 *
 * returns CartError, PcsError or null
 */
export function jsonParseError<R extends CartError | PcsError | Error3DS>(
  text: string = ""
): R | null {
  // Split up situations like this: 400 - {"message"
  const [_first, ...rest] = text.split("-");
  const maybeJson = rest.join("-").trim().replace(/\\/g, "");
  try {
    // If the second part exits then proceed to try to parse json
    return JSON.parse(maybeJson);
  } catch (error) {
    // If something went wrong just return null
    return null;
  }
}

/**
 * # Error Parse
 *  A Crude Way to get convert ugly errors like this:
 * 400 - {"message":"RH Gift Card has already been applied to the order","httpStatusCode":"400","httpStatusMsg":"Bad Request","errorCode":"CART_INVALID","cartId":"e630e51c-3e97-44aa-bedf-845954184640"}
 * into this:
 * "RH Gift Card has already been applied to the order"
 *
 */
export function errorParse(text: string = ""): string {
  const jsonError = jsonParseError(text);
  return (
    (jsonError as CartError)?.message ||
    (jsonError as PcsError)?.errorMessages?.CARD_INVALID ||
    text
  );
}

export function getErrorInfo(error?: PossibleErrorData): ErrorInfo | undefined {
  const result = {} as ErrorInfo;
  if (error?.timestamp) {
    result.timestamp = error.timestamp;
  }

  if (error?.trace_id) {
    result.trace_id = error.trace_id;
  }

  return Object.keys(result).length ? result : undefined;
}
