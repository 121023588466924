import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";

export const formatSearchText = (text: string): string => {
  return text.trim().replace(/\s+/g, "-");
};

export const removeNttSearchParams = (navigationState: string): string => {
  const env = useEnv();
  if (!navigationState || !yn(env?.FEATURE_URL_CHANGE)) {
    return navigationState;
  }

  const searchParams = new URLSearchParams(navigationState);

  ["Ntt", "Ntt2"].forEach(param => searchParams.delete(param));

  const updatedParams = searchParams.toString();
  return updatedParams ? `?${updatedParams}` : "";
};
