export default function (html: any) {
  if (typeof html !== "string") {
    return "";
  }

  return html
    .replace(/<\/?[^>]+(>|$)/g, " ")
    .replace(/\s+/g, " ")
    .trim();
}
