//lets not break pages anymore on bad json
export const verifyJson = (data, key) => {
  const isJSON = str => {
    if (str === "") return false;
    str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, "@");
    str = str.replace(
      /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
      "]"
    );
    str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, "");
    return /^[\],:{}\s]*$/.test(str);
  };
  const text =
    data && data[key] && data[key].model && data[key].model !== ""
      ? data[key].model
      : false;
  if (!text) return false;
  if (isJSON(text)) {
    return true;
  } else {
    return false;
  }
};
