export type BuildRoomsUrlResult = {
  didUrlChange: boolean;
  newUrl?: string;
};

export default function (location: any): BuildRoomsUrlResult {
  const searchParams = new URLSearchParams(location.search);
  const idRegex = /^\d+$/;
  const hasId = searchParams.has("id");
  const hasSlideId = searchParams.has("slideId");
  const id = (hasId && searchParams.get("id")) || "";
  const slideId = (hasSlideId && searchParams.get("slideId")) || "";
  const idValid = idRegex.test(id);
  const slideIdValid = idRegex.test(slideId);
  if (idValid && slideIdValid) {
    // params in good shape, just return it
    return { didUrlChange: false };
  }

  if (hasSlideId) {
    if (slideId.match(/^\/?[^-]+-[^-]+/)) {
      const splitSlideId = slideId.replace(/^\/?/, "").split("-");
      searchParams.set("id", splitSlideId[0]);
      searchParams.set("slideId", splitSlideId[1]);
      return {
        didUrlChange: true,
        newUrl: `${location.pathname}?${searchParams}`
      };
    }
  }

  if (!!!location.hash) {
    return { didUrlChange: false };
  }

  if (location.hash.match(/[=&]/)) {
    return { didUrlChange: false };
  }

  if (!location.hash.match(/^#\/?[^-]+-[^-]+/)) {
    return { didUrlChange: false };
  }

  const splitHash = location.hash.replace(/^#\/?/, "").split("-");
  searchParams.set("id", splitHash[0]);
  searchParams.set("slideId", splitHash[1]);

  return {
    didUrlChange: true,
    newUrl: `${location.pathname}?${searchParams}`
  };
}
