import { processEnvServer } from "hooks/useSsrHooks";
import { AppEnv } from "@RHCommerceDev/utils-app-env";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
import yn from "yn";

const env = getGlobal("_env");

export function isToggleOn(feature: keyof AppEnv): boolean {
  const fromSession = !processEnvServer && sessionStorage.getItem(feature);
  const fromLocal = !processEnvServer && localStorage.getItem(feature);
  return yn(env[feature]) || yn(fromSession) || yn(fromLocal);
}
