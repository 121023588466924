export const prasePGCropRules = pgCropRules => {
  try {
    let {
      width = "100%",
      height = "100%",
      ...restPGCropRules
    } = JSON?.parse(pgCropRules ?? "{}");

    height = parseFloat(height);
    height = Number?.isNaN(height) ? 100 : height;
    height = height > 100 ? 100 : height;

    width = parseFloat(width);
    width = Number?.isNaN(width) ? 100 : width;
    width = width > 100 ? 100 : width;

    return {
      width,
      height,
      ...restPGCropRules
    };
  } catch (err) {
    return {
      width: 100,
      height: 100
    };
  }
};

export default prasePGCropRules;
