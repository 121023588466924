import useIsoRedirect from "hooks-use-isoredirect";
import { shouldReloadForATG } from "utils/shouldReloadForATG";

export default function passThroughToOldExperience(
  cookies: { [name: string]: any },
  location: any,
  brand?: string
): any {
  const { pathname, search, hash } = location;
  if (shouldReloadForATG(cookies, { pathname, search, hash, brand })) {
    useIsoRedirect(
      `${pathname || ""}${search || ""}${hash || ""}`,
      "windowAssign"
    );
  }
  if (!/[?&]renderdesktop=/.test(search)) {
    const searchParams = new URLSearchParams(search);
    searchParams.set("renderdesktop", "true");
    const pathnameDecoded = decodeURIComponent(pathname);
    const newLocation = /\?/i.test(pathnameDecoded)
      ? `${pathnameDecoded}&${searchParams.toString()}`
      : `${pathnameDecoded}?${searchParams.toString()}`;
    window.location.replace(`${newLocation}`);
  }
  return null;
}
