export interface HtmlJson {
  tagName: string;
  textContent: string | null;
  attributes: {
    [key: string]: string;
  };
  children: HtmlJson[];
}

export const htmlToJson = (innerHTML?: string | null): HtmlJson => {
  const elementToJson = (e: Element): HtmlJson => {
    return {
      tagName: e.tagName,
      textContent: e.textContent,
      attributes: Array.from(e.attributes).reduce(
        (acc, { name, value }) => ({
          ...acc,
          [name]: value
        }),
        {}
      ),
      children: Array.from(e.children as any, elementToJson)
    };
  };

  if (!innerHTML) {
    return {
      tagName: "",
      textContent: "",
      attributes: {},
      children: []
    };
  }

  const element = document.createElement("div");
  element.innerHTML = innerHTML;

  return elementToJson(element.children[0]);
};

export default htmlToJson;
