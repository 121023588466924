import { countryZipCodesRegex } from "./regex";
import _uniq from "lodash.uniq";

const countryRegexList = [
  {
    countryRegex: /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/,
    country: "US",
    siteId: "RH"
  },
  {
    countryRegex: /^([a-zA-Z][0-9][a-zA-Z])\s*([0-9][a-zA-Z][0-9])$/,
    country: "CA",
    siteId: "RH"
  },
  {
    countryRegex: countryZipCodesRegex["GB"],
    country: "GB",
    siteId: "RHUK"
  },
  {
    countryRegex: /^[0-9]{5}$/,
    country: "DE",
    siteId: "RHUK"
  },
  {
    countryRegex: /^[0-9]{4}$/,
    country: "BE",
    siteId: "RHUK"
  },
  {
    countryRegex: /^[0-9]{5}$/,
    country: "ES",
    siteId: "RHUK"
  },
  {
    countryRegex: /^[0-9]{5}$/,
    country: "FR",
    siteId: "RHUK"
  }
] as const;

export const isValidPostalCode = (
  postalCode: string,
  countryCode: typeof countryRegexList[number]["country"] = "US"
) => {
  const getCountry = countryRegexList?.find(
    element => element.country === countryCode
  );

  return getCountry?.countryRegex?.test(postalCode?.trim());
};

export const getCountryCodeFromPostalCode = (postalCode: string) => {
  const getCountry = countryRegexList?.find(element => {
    if (element.countryRegex?.test(postalCode?.toUpperCase()?.trim())) {
      return element;
    }
  });
  return getCountry?.country;
};

export const formatePostalCode = (postalCode: string, countryCode: string) => {
  const { countryRegex = "" } =
    countryRegexList?.find(
      element => element.country === countryCode?.toUpperCase()
    ) ?? {};

  const matches = postalCode?.match(countryRegex)?.reduce((acc, match) => {
    if (match) {
      return [...acc, match?.trim()];
    }

    return acc;
  }, []);

  const [match, ...restMatch] = _uniq(matches);

  const formattedMatch = restMatch?.join(" ");

  if (formattedMatch) {
    return formattedMatch;
  } else {
    return match;
  }
};

export const getCountryCodeFromPostalCodeAndSiteId = (
  postalCode: string,
  siteId: string
) => {
  const getCountry = countryRegexList?.find(element => {
    if (
      element.countryRegex?.test(postalCode?.toUpperCase()?.trim()) &&
      siteId === element.siteId
    ) {
      return element;
    }
  });
  return getCountry?.country || "US";
};
