import { UserContext } from "types";
import { DEFAULT_CURRENCY_CODE } from "utils/constants";

export function parseUserContextCookie(value: string): UserContext {
  const cookieValue = value || "";
  const userContext = {} as any as UserContext;
  // set all userContext params
  cookieValue.split(",").map(c => {
    const split = c.split("=");
    userContext[split[0]] = split[1];
    return c;
  });
  if (!userContext.currencyCode) {
    userContext.currencyCode = DEFAULT_CURRENCY_CODE;
  }

  return userContext;
}
