import getBrand, { getBrandATG } from "hooks-use-brand/useBrand";
import getCountryFromUrl from "./getCountryFromUrl";
import { RHUK_HOST, RH_HOST } from "utils/constants";
import { Brand } from "types";
import {
  getCountryCodeFromPostalCode,
  isValidPostalCode
} from "./postalcode-validation";
import { checkCountryIsEu } from "./checkCountryIsEu";

type SiteId = Brand | typeof RHUK_HOST;

const getSiteId = (country = getCountryFromUrl(), isATG = false) => {
  const brand = isATG ? getBrandATG() : getBrand();
  const isCoreBrand = brand === RH_HOST;

  if (isCoreBrand) {
    if (checkCountryIsEu({ country })) {
      return RHUK_HOST;
    }

    return RH_HOST;
  }

  return brand;
};

export const getIsValidSiteId = (siteId: SiteId, postalCode: string) => {
  const country = getCountryCodeFromPostalCode(postalCode);

  if (siteId === RHUK_HOST) {
    return country && checkCountryIsEu({ country });
  } else {
    return isValidPostalCode(postalCode, country);
  }
};

export default getSiteId;
