type Navigation = {
  id: string;
  targetUrl: string;
  displayName: string;
  labelCode: string;
};
export const getAccountNavigation = (
  navigation: Navigation[],
  showMemberColumn: boolean
): Navigation[] => {
  if (!showMemberColumn) {
    return navigation.filter(nav => nav.displayName !== "Membership");
  }
  return navigation;
};
