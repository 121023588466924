export const trimDescription = (text: string) => {
  if (!text) {
    return "";
  }
  const myArray = text?.split(".");
  const array = [];
  myArray.forEach((value, index) => {
    if (index !== 0) array.push(value.length + array[index - 1]);
    else array.push(value.length);
  });
  const closest = array.reduce((a, b) => {
    return Math.abs(b - 160) < Math.abs(a - 160) ? b : a;
  });
  const index = array.indexOf(closest);
  const str =
    index > 0 ? myArray.slice(0, index + 1).join(".") : myArray[index];

  return str + ".";
};
