import buildPath from "utils/buildPath";

const processSaleNavigation = <T extends NavigationElement | null>(
  saleNavigation: T,
  callback?: (saleNavigation: T) => T
): T => {
  if (!saleNavigation) {
    return saleNavigation;
  }

  let url;

  if (saleNavigation?.targetUrl?.includes(".com")) {
    url = `https://${saleNavigation?.targetUrl}`;
  } else {
    url = buildPath((saleNavigation as any).targetUrl, { sale: "true" });
  }

  return {
    ...(saleNavigation as any),
    targetUrl: url,
    ...(callback?.(saleNavigation) as any),
    childCategories: (saleNavigation as any).childCategories?.map(
      (childCategory: T) => processSaleNavigation(childCategory, callback)
    )
  };
};

export default processSaleNavigation;
