import { PRODUCT_IMAGE_PRESETS } from "./constants";
import { useEnv } from "hooks/useEnv";

// TODO: Try to use JSON so that we can enumerate and statically type at the same time? ( just a guess )
export type ProductImagePresetKeys =
  | "mobileFull"
  | "mobileThumb"
  | "mobileZoom"
  | "rhir-mobileZoom"
  | "containerZoom"
  | "rhir-containerZoom"
  | "cgBanner"
  | "swatchLarge"
  | "full-width-xs"
  | "full-width-sm"
  | "full-width-md"
  | "full-width-lg"
  | "full-width-xl"
  | "rhrpdp-hero-colorizable-xs"
  | "rhrpdp-hero-colorizable-sm"
  | "rhrpdp-hero-colorizable-md"
  | "rhrpdp-hero-colorizable-lg"
  | "rhrpdp-hero-colorizable-xl"
  | "rhrpdp-zoom-colorizable-xs"
  | "rhrpdp-zoom-colorizable-sm"
  | "rhrpdp-zoom-colorizable-md"
  | "rhrpdp-zoom-colorizable-lg"
  | "rhrpdp-zoom-colorizable-xl"
  | "rhrpdp-zoom-noncolorizable-xs"
  | "rhrpdp-zoom-noncolorizable-sm"
  | "rhrpdp-zoom-noncolorizable-md"
  | "rhrpdp-zoom-noncolorizable-lg"
  | "rhrpdp-zoom-noncolorizable-xl"
  | "pg-card-xs"
  | "pg-card-md"
  | "cg-banner-xs"
  | "cg-banner-sm"
  | "cg-banner-md"
  | "cg-banner-lg"
  | "cg-banner-xl"
  | "cg-banner-full-xs"
  | "cg-banner-full-sm"
  | "cg-banner-full-md"
  | "cg-banner-full-lg"
  | "cg-banner-full-xl"
  | "pdp-hero-xs"
  | "pdp-hero-sm"
  | "pdp-hero-md"
  | "pdp-hero-lg"
  | "pdp-hero-xl"
  | "rhir-pdp-hero-xl"
  | "rhir-pdp-hero-xs"
  | "rhir-pdp-hero-sm"
  | "rhir-pdp-hero-md"
  | "rhir-pdp-hero-lg"
  | "rhir-pdp-hero-xl"
  | "PDPv2-hero-xs"
  | "PDPv2-hero-sm"
  | "PDPv2-hero-md"
  | "PDPv2-hero-lg"
  | "PDPv2-hero-xl"
  | "rhir-PDPv2-hero-xl"
  | "rhir-PDPv2-hero-xs"
  | "rhir-PDPv2-hero-sm"
  | "rhir-PDPv2-hero-md"
  | "rhir-PDPv2-hero-lg"
  | "rhir-PDPv2-hero-xl"
  | "swatch-large-xs"
  | "swatch-large-sm"
  | "swatch-large-md"
  | "swatch-large-lg"
  | "swatch-large-xl"
  | "swatch-order-small-xs"
  | "swatch-order-small-sm"
  | "swatch-order-small-md"
  | "swatch-order-small-lg"
  | "swatch-order-small-xl"
  | "swatch-order-large-xs"
  | "swatch-order-large-sm"
  | "swatch-order-large-md"
  | "swatch-order-large-lg"
  | "swatch-order-large-xl"
  | "monogram-large-xs"
  | "monogram-large-sm"
  | "monogram-large-md"
  | "monogram-large-lg"
  | "monogram-large-xl"
  | "relatedPCard"
  | "rhir-relatedPCard"
  | "cartLineItemCard"
  | "rhir-cartLineItemCard"
  | "addToProjectCard"
  | "rhir-mobileThumb";

const env = useEnv();
function getImagePreset(imageUrl: string, preset: ProductImagePresetKeys) {
  if (
    imageUrl.includes("RHR") &&
    ["cartLineItemCard", "relatedPCard"].includes(preset)
  ) {
    return "$IS-PDP-CARO$";
  }

  if (imageUrl.includes("RHR") && !imageUrl.includes("rhir")) {
    if (["mobileThumb"].includes(preset)) {
      return "$IS-PDP-CARO$";
    }
    // if (["pdp-hero-lg"].includes(preset)) {
    //   return "$PDP-IS-1600$";
    // }
    // if (["pdp-hero-xs"].includes(preset)) {
    //   return "$np-fullwidth-xs-RHR$";
    // }
    if (["PDPv2-hero-xl"].includes(preset)) {
      return "$PDPv2-IS-2000$";
    }
    if (["PDPv2-hero-lg"].includes(preset)) {
      return "$PDPv2-IS-1600$";
    }
    if (["PDPv2-hero-md"].includes(preset)) {
      return "$PDPv2-IS-1200$";
    }
    if (["PDPv2-hero-sm"].includes(preset)) {
      return "$PDPv2-IS-992$";
    }
    if (["PDPv2-hero-xs"].includes(preset)) {
      return "$PDPv2-IS-768$";
    }
    if (["mobileZoom"].includes(preset)) {
      return "$IS-PDP-mainzoom-RHR$";
    }
  }
  const presetKey = imageUrl?.includes("rhir") ? `rhir-${preset}` : preset;
  return PRODUCT_IMAGE_PRESETS[presetKey];
}
export default (
  imageUrl: string | undefined,
  preset: ProductImagePresetKeys | undefined,
  hasError?: boolean
) => {
  if (hasError) {
    return "https://media.restorationhardware.com/is/image/rhis/noimage";
  }

  if (!imageUrl || !preset) {
    return imageUrl;
  }

  // TODO: colorized hero images are rendering from the image
  // render server (/ir/render/) instead of the image server
  // (/is/image). For now, skip adding preset because it will
  // break ir images. Need to get the proper image url either
  // in the swatch service (preferable) or make a call to the
  // image service.
  if (imageUrl.includes("/ir/render")) {
    return imageUrl;
  }
  const delimeter = imageUrl.includes("?") ? "&" : "?";
  const presetValue = preset?.includes("np-fullwidth")
    ? preset
    : getImagePreset(imageUrl, preset);
  return `${imageUrl.trim()}${delimeter}${presetValue}`;
};
