import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import yn from "yn";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
import { getReqContext } from "./reqContext";

export const isSSRToggledWithClientRender = () => {
  if (processEnvServer) {
    const req = getReqContext();
    const featureSSR = yn(useIsoCookies(["FEATURE_SSR"])?.FEATURE_SSR);
    const forceCSR = req?.url?.includes("&ct=true");
    if (forceCSR) return false;
    if (featureSSR !== undefined) return featureSSR;
    const env = getGlobal("_env");
    return yn(env.FEATURE_SSR_WITH_CLIENT_RENDER);
  } else {
    const env = getGlobal("_env");
    return yn(env.FEATURE_SSR_WITH_CLIENT_RENDER);
  }
};

export const isSSRToggledCheck = () => {
  if (processEnvServer) {
    const featureSSR = yn(useIsoCookies(["FEATURE_SSR"])?.FEATURE_SSR);
    if (featureSSR !== undefined) return featureSSR;
    const env = getGlobal("_env");
    return yn(env.FEATURE_SSR);
  } else {
    const env = getGlobal("_env");
    return yn(env.FEATURE_SSR);
  }
};

export const isAEMSSRToggledWithClientRender = () => {
  if (processEnvServer) {
    const req = getReqContext();
    const featureAemSSR = yn(
      useIsoCookies(["FEATURE_AEM_SSR"])?.FEATURE_AEM_SSR
    );
    const forceCSR = req?.url?.includes("&ct=true");
    if (forceCSR) return false;
    if (featureAemSSR !== undefined) return featureAemSSR;
    const env = getGlobal("_env");
    return yn(env.FEATURE_SSR_WITH_CLIENT_RENDER);
  } else {
    const env = getGlobal("_env");
    return yn(env.FEATURE_SSR_WITH_CLIENT_RENDER);
  }
};

export const isAEMSSRToggledCheck = () => {
  if (processEnvServer) {
    const featureAemSSR = yn(
      useIsoCookies(["FEATURE_AEM_SSR"])?.FEATURE_AEM_SSR
    );
    if (featureAemSSR !== undefined) return featureAemSSR;
    const env = getGlobal("_env");
    return yn(env.FEATURE_AEM_SSR);
  } else {
    const env = getGlobal("_env");
    return yn(env.FEATURE_AEM_SSR);
  }
};
