/**
 * This function detects AEM's editor mode without requiring any specific AEM library.
 */
export function safeIsInAemEditor($window: Window | undefined = window) {
  const meta = $window?.document?.head?.querySelector?.(
    'meta[property="cq:wcmmode"]'
  );

  const seenAtMetaTag = /^(edit|preview|disabled)$/i.test(
    meta?.getAttribute("content") ?? ""
  );

  const seenAtUrl = /[?&]wcmmode=/i.test($window.location.href ?? "");

  return (
    seenAtMetaTag ||
    seenAtUrl ||
    /^\/editor\.html/.test($window?.location?.pathname) ||
    /^\/editor\.html/.test($window.parent?.location?.pathname)
  );
}
