export const IntlRegex = [
  /\/?us\/en\/?/,
  /\/?ca\/en\/?/,
  /\/?gb\/en\/?/,
  /\/?fr\/fr\/?/,
  /\/?fr\/en\/?/,
  /\/?nl\/nl\/?/,
  /\/?nl\/en\/?/,
  /\/?de\/de\/?/,
  /\/?de\/en\/?/,
  /\/?de\/(de|en)\/?/,
  /\/?be\/(nl|de|fr|en)\/?/,
  /\/?it\/it\/?/,
  /\/?it\/en\/?/,
  /\/?es\/es\/?/,
  /\/?es\/en\/?/
];

export const IntlPaths = [
  "/us/en/",
  "/ca/en/",
  "/ca/fr/",
  "/gb/en/",
  "/fr/fr/",
  "/fr/en/",
  "/nl/nl/",
  "/nl/en",
  "/de/de/",
  "/de/en/",
  "/be/nl/",
  "/be/fr/",
  "/be/de/",
  "/be/en/",
  "/it/it/",
  "/it/en/",
  "/es/en",
  "/es/es/"
];

const stripTrailingSlash = str => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

type Match = { locale: string; localeRegex: any };

export const testPathForLocale = path => {
  let result: Match | undefined;
  IntlRegex.forEach(regex => {
    const matches = path.match(regex);
    if (matches && matches.length > -1) {
      result = { localeRegex: regex, locale: matches[0] };
    }
  });

  return result;
};

export const getLocaleFromPath = path => {
  if (!path) return undefined;
  let match: Match = {
    locale: IntlPaths[0],
    localeRegex: IntlRegex[0]
  };
  const newMatch = testPathForLocale(path);
  return newMatch || match;
};

export const convertPathLocale = (
  path,
  localeFrom: RegExp | string,
  localeTo: string,
  languageOnly = false
) => {
  if (!path) return undefined;
  if (!localeFrom || !localeTo) return path;
  let destLocale = languageOnly
    ? stripTrailingSlash(localeTo).split("/")[1]
    : localeTo;
  //pass path thru unaltered if no localefrom or localeto is provided
  const matches = path.match(localeFrom);
  if (matches && matches.length > -1) {
    return path.replace(
      localeFrom,
      ("/" + destLocale + "/").replace(/\/\/+/g, "/")
    );
  }
  return path.replace(
    localeFrom,
    ("/" + destLocale + "/").replace(/\/\/+/g, "/")
  );
};

export const hasLocale = path => {
  let found = false;
  IntlPaths.map(intlPath => {
    if (path.includes(intlPath)) {
      found = true;
    }
  });
  return found;
};

export default { IntlRegex, IntlPaths };
