import { BRAND_MAP } from "hooks/useRHBrand";
export const isValidOrigin = (origin: string): boolean => {
  let valid = Object.keys(BRAND_MAP).some(h => origin.indexOf(h) > -1);
  //override for AEM authoring
  if (origin.indexOf("author") > -1) {
    valid = false;
  }
  console.log(
    "IS-VALID-ORIGIN",
    "\n--brandMap::",
    BRAND_MAP,
    "\n--origin::",
    origin,
    "\n--valid::",
    valid
  );
  return valid;
};
