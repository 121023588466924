interface MapArrayRecursivelyWith {
  array: Array<any>;
  nestedKey?: string;
  cb?: (arg) => any;
}

export const mapArrayRecursivelyWith = (arg: MapArrayRecursivelyWith) => {
  const { array = [], nestedKey = "", cb = value => value } = arg ?? {};

  return array?.map?.(value => {
    const newValue = cb(value);

    if (value?.[nestedKey]) {
      value[nestedKey] = mapArrayRecursivelyWith({
        array: value?.[nestedKey],
        nestedKey,
        cb
      });
    }

    return newValue;
  });
};
