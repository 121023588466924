import { routeToResourceMap } from "@RHCommerceDev/page-level-resources-schemas";

type ResourceMap = typeof routeToResourceMap;

export type RouteToResource = keyof ResourceMap;

type Resource = ResourceMap[RouteToResource];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

export type CombinedObject = UnionToIntersection<Resource>;

export const getResourceByRoute = <T extends RouteToResource>(
  route: T
): ResourceMap[T] => {
  const primaryRouteKey = String(route);
  if (primaryRouteKey.includes("home")) {
    return routeToResourceMap?.home as ResourceMap[T];
  }

  let secondaryRouteKey: string | undefined;
  if (primaryRouteKey.endsWith(".jsp")) {
    secondaryRouteKey = primaryRouteKey
      .split("/")
      .slice(-1)[0]
      .replace(".jsp", "");
  }

  return (routeToResourceMap?.[primaryRouteKey] ||
    routeToResourceMap?.[secondaryRouteKey] ||
    {}) as ResourceMap[T];
};

export const convertResourceToFallback = <T extends Resource>(resource: T) => {
  const fallback = {} as T;

  Object.keys(resource || {}).forEach(key => {
    const value = resource[key];
    if (typeof value !== "object") {
      fallback[key] = value || "";
    } else if (Array.isArray(value)) {
      fallback[key] = value.map(item => convertResourceToFallback(item));
    } else if (typeof value === "object") {
      fallback[key] = convertResourceToFallback(value);
    }
  });

  return fallback;
};
