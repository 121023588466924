import camelCase from "lodash.camelcase";

interface Properties {
  [key: string]: string;
}

export const cssStringToObj = (cssString: string) => {
  const regex = /([\w-]*)\s*:\s*([^;]*)/g;
  let match: any,
    properties: Properties = {};
  while ((match = regex.exec(cssString))) {
    properties[camelCase(match[1])] = match[2].trim();
  }
  return properties;
};
