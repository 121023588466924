import { getClientOrigin } from "./getClientOrigin";
import { getReqContext } from "./reqContext";
import { processEnvServer } from "hooks/useSsrHooks";
/*
  keep in sync with express server
*/

/* 
  keep in sync with express server 
*/

const REACT_ROUTES = [
  ".jsp",
  "/my-account",
  "/payment",
  "/customer-service",
  "/return",
  "/self-scheduler",
  "/cg",
  "/pg",
  "/pdp",
  "/search"
];

const AEM_ROUTES = ["/return-policy"];

export const REACT_MICROSITES = ["rhguesthouse"];

export const getIsReactMicrosite = () => {
  const req = getReqContext();
  const { pathname, hostname } = window?.location ?? {};
  const originSSR = getClientOrigin();
  const originHost = processEnvServer ? originSSR : hostname;
  return REACT_MICROSITES?.some(reactRoute => originHost.includes(reactRoute));
};

export const getIsReactRoute = () => {
  const { pathname, hostname } = window?.location ?? {};
  const originSSR = getClientOrigin();
  const req = getReqContext();
  const originHost = processEnvServer ? originSSR : hostname;
  if (!processEnvServer) {
    const isRHGuestHouseHomePage =
      originHost.includes("rhguesthouse") &&
      !processEnvServer &&
      pathname === "/";

    if (isRHGuestHouseHomePage) {
      return true;
    }
  }
  if (
    pathname?.includes("/return-policy.jsp") ||
    req?.url?.includes("/return-policy.jsp")
  ) {
    return true;
  }
  if (
    AEM_ROUTES?.some(reactRoute => pathname?.includes(reactRoute)) ||
    AEM_ROUTES?.some(reactRoute => req?.url?.includes(reactRoute))
  ) {
    return false;
  }
  return (
    REACT_ROUTES?.some(reactRoute => pathname?.includes(reactRoute)) ||
    REACT_ROUTES?.some(reactRoute => req?.url?.includes(reactRoute))
  );
};

export default getIsReactRoute;
