import { PG_SEARCH_TERM } from "utils/constants";

export const addPgTermFallback = (url: string, ssr: boolean = false) => {
  // this is only happening on the client, is a user interaction thats why i can use the new URL with base

  const [baseUrl, queryString] = url.split("?");
  const params = new URLSearchParams(queryString);

  const cleanedParams = new URLSearchParams();

  if (queryString?.length) {
    for (const [key, value] of params?.entries()) {
      const cleanedKey = key.trim();
      const cleanedValue = value.trim();
      cleanedParams.append(cleanedKey, cleanedValue);
    }
  }

  const cleanedUrl = `${baseUrl}${
    queryString?.length ? "?" + cleanedParams.toString() : ""
  }`;

  const urlData = new URL(cleanedUrl?.trim(), window.location.href);
  const hasCategoryId = urlData.searchParams.has("categoryId");
  const hasPgTerm = urlData.searchParams.has(PG_SEARCH_TERM);

  if (
    cleanedUrl?.startsWith("/") &&
    urlData.pathname === "/catalog/category/products.jsp" &&
    !hasPgTerm &&
    hasCategoryId
  ) {
    urlData.searchParams.set(
      PG_SEARCH_TERM,
      `category:${urlData.searchParams.get("categoryId")}`
    );
    return urlData.toString()?.replace(window?.location?.origin, "");
  } else {
    return cleanedUrl;
  }
};
