import {
  memo,
  ComponentType,
  MemoExoticComponent,
  ComponentProps
} from "react";
import deepEqual from "react-fast-compare";

type Options = { deep?: boolean };

const DEBUG = false;

function memoize<T extends ComponentType<any>>(
  Component: T,
  _optionsOrComparitor?:
    | ((
        prevProps: Readonly<ComponentProps<T>>,
        nextProps: Readonly<ComponentProps<T>>
      ) => boolean)
    | Options
): MemoExoticComponent<T> {
  const fn = !_optionsOrComparitor
    ? deepEqual
    : typeof _optionsOrComparitor === "function"
    ? _optionsOrComparitor
    : _optionsOrComparitor?.deep === false
    ? undefined
    : deepEqual;

  const returnValue = memo(Component, fn);
  if (DEBUG) {
    if (fn === deepEqual) {
      // @ts-ignore
      return (props: ComponentProps) => {
        if (
          Object.values(props).every(v =>
            ["string", "number", "boolean", "function"].includes(typeof v)
          )
        ) {
          console.info(
            "<--------\n",
            "This component MAY NOT need prop deep comparison\n",
            Component,
            props,
            "\n-------->"
          );
        }
        return returnValue(props);
      };
    } else if (fn === undefined) {
      // @ts-ignore
      return (props: ComponentProps) => {
        if (
          Object.values(props).some(
            v => !["string", "number", "boolean", "function"].includes(typeof v)
          )
        ) {
          console.info(
            "<--------\n",
            "This component MAY need prop deep comparison\n",
            Component,
            props,
            "\n-------->"
          );
        }
        return returnValue(props);
      };
    } else {
      return returnValue;
    }
  } else {
    return returnValue;
  }
}

export default memoize;
