import {
  COOKIE_MAX_AGE_14_DAYS,
  COOKIE_MAX_AGE_34_DAYS,
  COOKIE_MAX_AGE_90_DAYS
} from "./constants";

export const excludedCookies = ["ak_bmsc", "endpoint", "SESSIONID"];

const cookiesStorage = {
  emailSignUpDialogPageCount: {
    party: "3rd Party",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  lastSortMenu: {
    party: "3rd Party",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  PROMO: {
    party: "3rd Party",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  GHNY_INTRO_VIDEO: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  video: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  manual_country_selection: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  SubscriberID: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  JobID: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  ListID: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  OriginalLinkID: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  MemberID: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  BatchID: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  Medium: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },

  navigating_from_search: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  search_access: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  ghvid_played: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  sipId: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },

  KEYCLOAK_IDENTITY: {
    party: "3rd Party",
    duration: "90 days",
    preference: "analytics"
  },
  KEYCLOAK_SESSION: {
    party: "3rd Party",
    duration: "90 days",
    preference: "analytics"
  },
  disabled_hint_scroll_x: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  selected_brand: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  fusion_gcp: { party: "RH", duration: "90 days", preference: "analytics" },
  fusion_id: { party: "RH", duration: "90 days", preference: "analytics" },
  userName: {
    party: "1st. Party",
    duration: "90 days",
    preference: "analytics"
  },
  AUTH_SESSION_ID: {
    party: "3rd Party",
    duration: "90 days",
    preference: "analytics"
  },
  is_user_save_cookie_preferences: {
    party: "RH",
    duration: "90 days",
    preference: "analytics"
  },
  "kc-callback-{DynamicID}": {
    party: "1st Party",
    duration: "90 days",
    preference: "analytics"
  },
  mbox: {
    party: "3rd Party",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  "at_check ": {
    party: "3rd Party",
    duration: "session",
    preference: "preferencesFunctionalityCookie"
  },
  fusion_search: {
    party: "RH ",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  rb: {
    party: "RH ",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  PF_EXP: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  language_cookie: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  measurement_cookie: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  pc: {
    party: "RH",
    duration: "90 days",
    preference: "strictlyNecessary" // We force this cookie to be stored as it's strictly necessary and have to bypass the cookie policies rules - 11/20/23 - SHOP-4903
  },
  pgGridChoice: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  cgGridChoice: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  pgId: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  cgId: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  cgIdPreview: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  NEWEXP: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  FEATURE_AEM_RENDER_CONTROL: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  FEATURE_WISTIA_OPTIMIZATIONS: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  FEATURE_EMAIL_MARKETING_FOOTER_NA: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  FEATURE_EMAIL_MARKETING_POPUP_UK: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  FEATURE_EMAIL_MARKETING_FOOTER_UK: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  FEATURE_EMAIL_MARKETING_FOOTER_DE: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  },
  cleanedCookie: {
    party: "RH",
    duration: "34 days",
    preference: "preferencesFunctionalityCookie"
  },
  fusion_upgrade_ramp: {
    party: "RH",
    duration: "14 days",
    preference: "preferencesFunctionalityCookie"
  },
  NEWX0422: {
    party: "RH",
    duration: "session",
    preference: "preferencesFunctionalityCookie"
  },
  user_cookie_preferences: {
    party: "RH",
    duration: "90 days",
    preference: "strictlyNecessary"
  },
  country_cookie: {
    party: "RH",
    duration: "90 days",
    preference: "strictlyNecessary"
  },
  SESSIONID: {
    party: "RH",
    duration: "90 days",
    preference: "strictlyNecessary"
  },
  user_accepted_all_cookies: {
    party: "RH",
    duration: "90 days",
    preference: "strictlyNecessary"
  },
  FEATURE_SUPPRESS_UK: {
    party: "RH",
    duration: "90 days",
    preference: "strictlyNecessary"
  },
  _gid: {
    party: "1st. Party",
    duration: "90 days",
    preference: "thirdPartyAnalytics"
  },
  "_gat_UA-6578887-5": {
    party: "1st. Party",
    duration: "90 days",
    preference: "thirdPartyAnalytics"
  },
  _ga: {
    party: "1st. Party",
    duration: "90 days",
    preference: "thirdPartyAnalytics"
  },
  _ga_0J58GMWPJ0: {
    party: "1st. Party",
    duration: "90 days",
    preference: "thirdPartyAnalytics"
  },
  RH_ALERT: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie"
  }
} as const;

const localSessionStorage = {
  pagination_count: {
    party: "RH",
    duration: "session",
    preference: "preferencesFunctionalityCookie",
    type: "sessionStorage"
  },
  RH_ALERT: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "sessionStorage"
  },
  pdpViewItem: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "sessionStorage"
  },
  isSubscribed: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  saidNoThanks: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  is_user_save_cookie_preferences: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  "analytics-category-title": {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  "analytics-product-data": {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },

  "analytics-access": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-catId": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-category": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-categoryForProduct": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-emptyProduct": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-previous-url": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-search": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-navCatalog": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-Catalog": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-navigatingFromSearch": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-navigationFromBanner": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-searchbar-enter": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  video: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },

  clientID: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  fusion_data: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  payload: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  sipId: {
    party: "RH",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  wistia: {
    party: "3rd Party",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  "wistia-video-progress-": {
    party: "3rd Party",
    duration: "90 days",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  "tgt:tlm:": {
    party: "RH",
    duration: "session",
    preference: "preferencesFunctionalityCookie",
    type: "localStorage"
  },
  REACT_APP_KEYCLOAK_CLIENTID: {
    party: "RH",
    duration: "90 days",
    preference: "strictlyNecessary",
    type: "localStorage"
  },
  fromNav: {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-catalog": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  "analytics-searchtermclick": {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  isExternal: {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  },
  isLoggedin: {
    party: "RH",
    duration: "90 days",
    preference: "analytics",
    type: "localStorage"
  }
} as const;

export const durationMap = {
  session: undefined,
  "90 days": COOKIE_MAX_AGE_90_DAYS,
  "34 days": COOKIE_MAX_AGE_34_DAYS,
  "14 days": COOKIE_MAX_AGE_14_DAYS
} as const;

export const storagesMap = {
  cookiesStorage,
  localSessionStorage
};

export type StorageType = keyof typeof storagesMap;
export type LocalSessionStorageKeys = keyof typeof localSessionStorage;
export type CookiesKeys = keyof typeof cookiesStorage;

export type StorageItemType = {
  party: string;
  duration: keyof typeof durationMap;
  preference:
    | "thirdPartyAnalytics"
    | "analytics"
    | "preferencesFunctionalityCookie"
    | "strictlyNecessary";
};
