import { IntlPaths } from "utils/intlUtils";

export const mapping = {
  BC: "/bc/us/en/home",
  MO: "/mo/us/en/home",
  IN: "/in/us/en/interiors",
  OD: "/od/us/en/home",
  SH: "/sh/us/en/home",
  BH: "/bh/us/en/home",
  RH: "/rh/us/en/home",
  TN: "/tn/us/en/home",
  CN: "/cn/us/en/home"
};

export const SUBDOMAIN = {
  BH: `rhbeachhouse`,
  SH: `rhskihouse`,
  TN: `rhteen`,
  BC: `rhbabyandchild`,
  OD: "rhoutdoor",
  MO: `rhmodern`,
  IN: `rhinteriors`,
  CN: `rhcontemporary`
};

export const getAemRootPath = (brand, locale) => {
  return `/${brand}/${locale}/${
    brand.toUpperCase() === "IN" ? "interiors" : "home"
  }`
    .replace(/\/\/+/g, "/")
    .toLowerCase();
};

export const isRootPath = (path: string) => {
  if (path === "home" || path === "/home") return true;
  const isInPath =
    IntlPaths.indexOf(path) > -1 ||
    IntlPaths.indexOf(path + "/") > -1 ||
    IntlPaths.indexOf("/" + path) > -1 ||
    IntlPaths.indexOf("/" + path + "/") > -1;
  return isInPath ? true : path === "/" ? true : false;
};

export const getBrandFromHostname = (hostname, isContentPath = false) => {
  const SUBDOMAIN = {
    BH: `rhbeachhouse`,
    SH: `rhskihouse`,
    TN: `rhteen`,
    BC: `rhbabyandchild`,
    OD: "rhoutdoor",
    MO: `rhmodern`,
    IN: `rhinteriors`,
    CN: `rhcontemporary`,
    //guesthouse is currently RH brand
    RH: `rhguesthouse`
  };
  const match = Object.entries(SUBDOMAIN).find(([brand, host]) => {
    return hostname.search(host) > -1;
  });
  return match
    ? match[isContentPath ? 1 : 0]
      ? match[isContentPath ? 1 : 0]
      : "RH"
    : "RH";
};

export const convertBrand = (url, host, path, brand, isServer) => {
  const pathsArray = Object.values(SUBDOMAIN);
  let found: string | undefined = undefined;
  pathsArray.forEach(subPath => {
    if (path.indexOf(subPath) > -1) {
      found = subPath;
    }
  });

  // if (found) {
  //   path =  path.replace(found, SUBDOMAIN[brand?.toUpperCase()] || "rh");
  // }

  // return host + '/' + path;

  if (found) {
    if (isServer) {
      return (
        host +
        "/" +
        path.replace(found, SUBDOMAIN[brand?.toUpperCase()] || "rh")
      );
    } else {
      return url.replace(found, SUBDOMAIN[brand?.toUpperCase()] || "rh");
    }
  } else {
    return url;
  }
};

export const getBrandFromKey = brand => {
  return SUBDOMAIN[brand] || "RH";
};
