import { KeyboardEvent } from "react";

type AnyFunction = (...args: any[]) => any;

export const withKeyboardEvent = (fn: AnyFunction, ...args: any[]) => {
  return (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === "Space") {
      event.preventDefault();
      fn(...args);
    }
  };
};

export const handleKeyboardPressWithEvent =
  (fn, triggerKey?: string) =>
  (event, ...args) => {
    const keysToCheck = triggerKey ? [triggerKey] : ["Enter", "Space"];
    if (keysToCheck.includes(event.key)) {
      event.preventDefault();
      fn(event, ...args);
    }
  };
