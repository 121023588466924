import { createStyles, makeStyles, Theme } from "./material-ui-core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MuiButton: {
      root: {
        color: "#999999"
      }
    },
    containedPrimary: {
      color: "#FFFFFF",
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#404040",
        borderColor: "#404040"
      }
    },
    outlinedPrimary: {
      color: "#212121",
      border: "1px solid rgba(33, 33, 33, 0.5)"
    }
  })
);
export default useStyles;
