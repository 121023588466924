import { getGlobal } from "@RHCommerceDev/utils/Globals/getGlobal";
import type * as analytics from "./index";
const DEFER_ANALYTICS_MS = 3000;
const delay = (t: number) => new Promise(resolve => setTimeout(resolve, t));
const AP = delay(DEFER_ANALYTICS_MS).then(() => import("./index"));

const { ENV_ID } = getGlobal("_env");

type CB = (args: typeof analytics) => void;

export default async (cb: CB) => {
  if (!globalThis?.process?.env?.SERVER) cb(await AP);
};

type Decorate = (a: Readonly<typeof analytics>) => typeof analytics;

export const withLogs: Decorate = a => ({
  ...a,

  emitAnalyticsEvent: function (...args) {
    if (ENV_ID === "LOCAL") {
      console.log("analytics log", ...args);
    }

    return a.emitAnalyticsEvent.apply(this, args);
  }
});
