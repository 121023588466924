const { location, history } = window;

export const removeQueryParams = (params: Array<string> = []) => {
  const p = new URLSearchParams(location.search);
  params.forEach(param => p.delete(param));
  const ps = p.toString();
  if (location.search.slice(1) !== ps) {
    history.replaceState(
      {},
      "",
      `${location.pathname}${ps ? "?" : ""}${ps}${location.hash}`
    );
  }
};
