interface LoadGoogleTagManagerArgs {
  w?: Window;
  d?: Document;
  s?: string;
  l?: string;
  i?: string;
}

export const loadGoogleTagManager = ({
  w = window,
  d = window?.document,
  s = "script",
  l = "dataLayer",
  i = "GTM-NSJJ6W9"
}: LoadGoogleTagManagerArgs = {}) => {
  if (d?.querySelector("#gtm")) {
    return;
  }

  const innerLoad = () => {
    w[l] = w[l] || [];
    w[l]?.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l != "dataLayer" ? "&l=" + l : "";
    j.setAttribute("id", "gtm");
    // @ts-ignore
    j.defer = true;
    // @ts-ignore
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode?.insertBefore(j, f);
  };

  if (window._rh_wait_for_wistia_hero) {
    window._rh_on_wistia_hero_play = window._rh_on_wistia_hero_play || [];
    window._rh_on_wistia_hero_play.push(innerLoad);
  } else {
    innerLoad();
  }
};

export default loadGoogleTagManager;
