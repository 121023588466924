let resMobile: boolean, resDesktop: boolean;

export const isMobile = (): boolean => {
  if (resMobile === undefined) {
    resMobile = document.cookie.includes("PF_EXP=MOBILE");
  }
  return resMobile;
};

export const isDesktop = (): boolean => {
  if (resDesktop === undefined) {
    resDesktop = document.cookie.includes("PF_EXP=DESKTOP");
  }
  return resDesktop;
};
